<template>

  <div class="meeting-room">

    <template v-if="loading">
      <div class="meetingLoading">
        <div class="meetingSignInBackdrop">
        </div>
        <div class="loadingSpinner"></div>
      </div>  
    </template>

    <template v-if="loggedIn">
      <div v-if="editor && auth" class="productEdits">
        <!-- <div class="productSelectLayout">
          <h3>1. Select layout</h3>
          <select @change="setMeetingLayout($event)">
            <option value="1">Scene A</option>
            <option value="2">Scene B</option>
          </select>
        </div> -->
        <div class="productUploadFile">
          <h3>1. Upload a file</h3>
          <input class="productFileInput" type="file" accept="application/pdf,video/mp4">
          <p>Max file size: 25MB</p>
        </div>
        <div class="productList">
          <div class="productStepInfo">
            <h3>2. Select products</h3>
            <p>Drag and drop products to the podiums.</p>
          </div>
          <div class="productListItems">
            <div class="productDragItem" draggable="true" v-for="product in getProductList()" @dragstart="setMovingElement(product)" v-bind:key="product">
              <PreloadedImage v-if="mediaUrl(product.data.productImage)"  :src="mediaUrl(product.data.productImage)" :style="`width: 46px; height: 46px; padding-bottom: 2px`"/>
              <p>{{product.name}}</p>
            </div>
          </div>
        </div>
        <Button @click="createMeeting()" class="button--icon productEdit_Button">Finalize room</Button>
      </div>

      <div class="media" v-if="meetingTemplate.media && !editor" :style="`
          overflow:hidden;
          position: `+meetingTemplate.media.style.position+`;
          left: `+meetingTemplate.media.style.left+`px;
          top: `+meetingTemplate.media.style.top+`px;
          width: `+meetingTemplate.media.style.width+`px;
          height: `+meetingTemplate.media.style.height+`px;
          background-color: `+meetingTemplate.media.style.backgroundColor+`;
          transform: perspective(`+meetingTemplate.media.style.transform.perspective+`px) skewX(`+meetingTemplate.media.style.transform.skewX+`deg) skewY(`+meetingTemplate.media.style.transform.skewY+`deg) rotateX(`+meetingTemplate.media.style.transform.rotateX+`deg) rotateY(`+meetingTemplate.media.style.transform.rotateY+`deg);
          border: `+meetingTemplate.media.style.border+`;`">
          <div v-if="!editor && meetingTemplate.media.mediaContent[0] && meetingTemplate.media.mediaContent[0].substring(meetingTemplate.media.mediaContent[0].lastIndexOf('.')+1, meetingTemplate.media.mediaContent[0].length) == 'pdf'" @click="toggleFullscreenPDF()" class="fullscreenPDFBtn" style="background-image: url('img/video-fullscreen-btn.3cbfe037.svg');"></div>
          <div class="mediaPDFContainerOverlay_1" v-if="!meetingTemplate.media.mediaContent[0]"></div>
          <object v-if="!editor && meetingTemplate.media.mediaContent[0] && meetingTemplate.media.mediaContent[0].substring(meetingTemplate.media.mediaContent[0].lastIndexOf('.')+1, meetingTemplate.media.mediaContent[0].length) == 'pdf'" class="mediaPDFContainer" :data="`https://intellianeventgateway-staging.azurewebsites.net/uploads/`+meetingTemplate.media.mediaContent[0] + `?#page=1&view=FitH&scrollbar=0&toolbar=0&navpanes=0`" type="application/pdf" style="width: calc(100% + 18px); height: 100%;"></object>
          <video v-if="!editor && meetingTemplate.media.mediaContent[0] && meetingTemplate.media.mediaContent[0].substring(meetingTemplate.media.mediaContent[0].lastIndexOf('.')+1, meetingTemplate.media.mediaContent[0].length) == 'mp4'" type="video/mp4" controls style="width: 100%; height: 100%;">
            <source :src="`https://intellianeventgateway-staging.azurewebsites.net/uploads/`+meetingTemplate.media.mediaContent[0]" type="video/mp4" />
          </video>
      </div>
      <div class="mediaFullscreen" v-if="meetingTemplate.media && meetingTemplate.media.mediaContent[0] && mediaIsFullscreen">
        <div class="meetingSignInBackdrop">
        </div>
            <div class="mediaFullscreenContentPrevPage" @click="pdfnavigatePage(false)"></div>
            <div class="mediaFullscreenContentNextPage" @click="pdfnavigatePage(true)"></div>
        <div class="mediaFullscreenContent">
            <div class="popup__close-btn" @click="toggleFullscreenPDF()"></div>
            <div class="mediaPDFContainerOverlay_2"></div>
            <object class="mediaPDFContainerFull" :data="`https://intellianeventgateway-staging.azurewebsites.net/uploads/`+meetingTemplate.media.mediaContent[0] +`?#page=`+this.mediaPDFCurrentPage+`&view=Fit&scrollbar=0&toolbar=0&navpanes=0`" type="application/pdf" style="width: calc(100% + 18px); height: 100%;"></object>
        </div>
      </div>


      <div v-for="productArea in meetingTemplate.productAreas" v-bind:key="productArea" class="productArea" :style="`
        position: absolute;
        z-index: `+productArea.position.zIndex+`; 
        top: `+productArea.position.top+`px; 
        left: `+productArea.position.left+`px; 
        width: `+productArea.position.width+`px; 
        height: `+productArea.position.height+`px; 
        background-size: cover;` + [!productArea.productId ? 'background-image: url('+ productArea.placeholderImg + ')' : '']">

        <div @drop="drop($event, productArea)" @dragover="allowDrop($event)" class="productPlacementSpot" v-if="!productArea.productId && editor" :style="`
        position: absolute;
        width: 100px;
        height: 40px;
        top: `+productArea.productPosition.top+`px; 
        left: `+productArea.productPosition.left+`px;
        transform: translateX(-50%) translateY(-40px);
        `">
          <svg style="display: block; margin: auto;" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g id="Group_272" data-name="Group 272" transform="translate(-808 -868)">
              <g id="Rectangle_186" data-name="Rectangle 186" transform="translate(808 868)" fill="rgba(255,255,255,0.8)" stroke="#279ad0" stroke-linejoin="round" stroke-width="1" stroke-dasharray="4">
                <rect width="40" height="40" rx="20" stroke="none"/>
                <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="none"/>
              </g>
              <g id="Group_267" data-name="Group 267" transform="translate(804.723 871.353)">
                <line id="Line_34" data-name="Line 34" x2="18.445" y2="0.002" transform="translate(14.055 16.646)" fill="none" stroke="#279ace" stroke-width="1"/>
                <line id="Line_35" data-name="Line 35" x2="18.445" y2="0.002" transform="translate(23.278 7.424) rotate(90)" fill="none" stroke="#279ace" stroke-width="1"/>
              </g>
            </g>
          </svg>

        </div>
        <div class="product" v-if="productArea.productId" :style="`
          position: absolute; 
          pointer-events: none;
          top: `+productArea.productPosition.top+`px; 
          left: `+productArea.productPosition.left+`px;`
          ">

          <PreloadedImage 
          :src="mediaUrl(product(productArea.productId).data.productImage)"
          :style="`transform: translateX(-50%) scale(`+product(productArea.productId).data.productImageScaleForMeetingRoom+`) translateY(`+product(productArea.productId).data.productImageSpaceBelowInPixels+`px);
          transform-origin: bottom center; position: absolute; bottom: 0;`"
          />
          <Button v-if="editor && productArea.placeholderImg.includes('tree')" 
          :style="`pointer-events: all;`+ `transform: translateX(-50%) translateY(-`+ ( (20 + 40) + (GetPreloadedImage(mediaUrl(product(productArea.productId).data.productImage)).height * product(productArea.productId).data.productImageScaleForMeetingRoom)) +`px);
          transform-origin: bottom center; position: absolute; top: `+product(productArea.productId).data.productImageSpaceBelowInPixels * product(productArea.productId).data.productImageScaleForMeetingRoom+`px;`" 
          @click="productArea.productId = null" class="meeting-room__product-button button--icon-cross">{{product(productArea.productId).name}}</Button>

          <Button v-if="!editor && productArea.placeholderImg.includes('tree')" 
          :style="`pointer-events: all;`+ `transform: translateX(-50%) translateY(-`+ ( (20 + 40) + (GetPreloadedImage(mediaUrl(product(productArea.productId).data.productImage)).height * product(productArea.productId).data.productImageScaleForMeetingRoom)) +`px);
          transform-origin: bottom center; position: absolute; top: `+product(productArea.productId).data.productImageSpaceBelowInPixels * product(productArea.productId).data.productImageScaleForMeetingRoom+`px;`" 
          @click="selectedProduct = product(productArea.productId)" class="meeting-room__product-button button--icon-plus">{{product(productArea.productId).name}}</Button>

          <Button v-if="editor && !productArea.placeholderImg.includes('tree')" :style="`pointer-events: all;`" @click="productArea.productId = null" class="meeting-room__product-button button--icon-cross">{{product(productArea.productId).name}}</Button>
          <Button v-if="!editor && !productArea.placeholderImg.includes('tree')" :style="`pointer-events: all;`" @click="selectedProduct = product(productArea.productId)" class="meeting-room__product-button button--icon-plus">{{product(productArea.productId).name}}</Button>
        </div>
        
      </div>

      <div v-for="backgroundArea in meetingTemplate.backgroundAreas" v-bind:key="backgroundArea" class="backgroundArea" :style="`
        position: absolute;
        pointer-events: none;
        z-index: `+backgroundArea.position.zIndex+`; 
        top: `+backgroundArea.position.top+`px; 
        left: `+backgroundArea.position.left+`px; 
        width: `+backgroundArea.position.width+`px; 
        height: `+backgroundArea.position.height+`px; 
        background-size: contain; 
        background-image: url(`+ backgroundArea.img + `)`">
      </div>

      <div v-if="meetingCreated">
        <div class="meetingSignInBackdrop">
          <div class="meetingCodeContainer">
            <div class="popup__close-btn" @click="goBack()"></div>
            <h2>YOUR ROOM IS READY</h2>
            <p>Your curation is now ready to be shared.<br>The guest access code to enter the room is:</p>
            <input :value="meetingCreated" class="meetingSignIn_CodeInput" type="text">
            <button class="meetingSignIn_Button" @click="copyRoomLink()">Copy link</button>
            <button class="meetingSignIn_Button" @click="goToRoom()">Go to room</button>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!loggedIn">
      <div class="meetingSignInBackdrop">
      </div>  
        <template v-if="!editor">
          <div class="meetingSignIn">
          <div class="popup__close-btn" @click="goBack()"></div>
            <h2>VIEW CURATED PRODUCTS</h2>
            <p>This room is used to showcase a curated range of products.<br>Please enter the access code to enter the room.</p>
            <input class="meetingSignIn_CodeInput meetingCodeInput" type="text" placeholder="Enter code here">
            <span class="meetingSignInError">{{AttendMeetingInvalid}}</span>
            <button @click="loginAttend()" class="meetingSignIn_Button" noIcon="true">Enter the room</button>
            <p style="float:left;" @click="editor = true; AttendMeetingInvalid = ''">Partner <b>login</b></p>
            <p style="float:right;margin: 0px 79px 47px 0px; font-size: 14px;cursor: pointer;"><a style="text-decoration: none; color: inherit;" href="https://intellian.force.com/partner/PP_PartnerLogin" target="_blank">Partner <b>request login ></b></a></p>
          </div>
        </template>
        <template v-if="editor">
          <div class="meetingSignIn">
            <div class="popup__close-btn" @click="goBack()"></div>
            <h2>CURATE PRODUCTS</h2>
            <p>Enter your Intellian World<br>Partner ID to continue.</p>
            <input class="meetingSignIn_CodeInput" type="password" placeholder="Secure ID">
            <span class="meetingSignInError">{{AttendMeetingInvalid}}</span>
            <button @click="loginCreate()" class="meetingSignIn_Button">Begin curation</button>
            <div style="width: 100%; text-align: center; font-size: 14px; cursor:pointer;" @click="editor = false; AttendMeetingInvalid = ''">Go <b>back</b></div>
          </div>
        </template>
        <div class="media" v-if="meetingTemplate.media" :style="`
          overflow:hidden;
          position: `+meetingTemplate.media.style.position+`;
          left: `+meetingTemplate.media.style.left+`px;
          top: `+meetingTemplate.media.style.top+`px;
          width: `+meetingTemplate.media.style.width+`px;
          height: `+meetingTemplate.media.style.height+`px;
          background-color: `+meetingTemplate.media.style.backgroundColor+`;
          transform: perspective(`+meetingTemplate.media.style.transform.perspective+`px) skewX(`+meetingTemplate.media.style.transform.skewX+`deg) skewY(`+meetingTemplate.media.style.transform.skewY+`deg) rotateX(`+meetingTemplate.media.style.transform.rotateX+`deg) rotateY(`+meetingTemplate.media.style.transform.rotateY+`deg);
          border: `+meetingTemplate.media.style.border+`;`">
          <div class="mediaPDFContainerOverlay_1"></div>
      </div>
    </template>

  </div>

  <ProductSpecsPopup v-if="selectedProduct" :product="selectedProduct" @close="selectedProduct = null"/>

  <Popup type="video" v-if="popupVideoData" @close="popupVideoData = null">
    <BasicVideo
      :videoMediaFile="popupVideoData.videoMediaFile"
      :videoLink="popupVideoData.videoLink"
      :controls="true"
      :loop="false"
      :autoplay="true"
      :muted="false"/>
  </Popup>


</template>

<script>
import {goToParentScene} from '@/helpers/navigationHelper';
import { getUmbracoMediaItemSrc, getPreloadedImage } from '@/helpers/assetHelper';
import Popup from '@/components/Popup';
import BasicVideo from '@/components/BasicVideo';
import PreloadedImage from '@/components/PreloadedImage';
import Button from '@/components/Button';
import ProductSpecsPopup from '@/components/ProductSpecsPopup';
import eventBus from '@/helpers/eventBus';

export default {
  name: 'MeetingView',
  components: { Popup, BasicVideo, PreloadedImage, Button, ProductSpecsPopup},
  props: {
    scenePath: String
  },
  data()
  {
    return {
      selectedProduct: null,
      popupVideoData: null,
      meetingTemplateA: {
        media : {
          mediaContent : [],
          style : {
            position: "absolute",
            left: 71,
            top: 184,
            width: 517,
            height: 281,
            backgroundColor : "#000",
            borderStroke: 3,
            transform : {
              perspective : 507,
              skewY : 5.2,
              skewX : 0,
              rotateY :  14.2,
              rotateX :  0
            }
          }
        },
        productAreas : [
          {
            position : {
              zIndex: 11,
              top: 577,
              left: 319,
              width: 573,
              height: 345
            },
            placeholderImg : "img/plant.1f2ccc8c.png",
            productPosition : {
              top: 250,
              left: 280
            },
            productId : null
          },
          {
            position : {
              zIndex: 10,
              top: 114,
              left: 760,
              width: 528.75,
              height: 702
            },
            placeholderImg : "img/tree.94897c71.png",
            productPosition : {
              top: 650,
              left: 280
            },
            productId : null
          },
          {
            position : {
              zIndex: 11,
              top: 745,
              left: 1309,
              width: 369,
              height: 124.5
            },
            placeholderImg : "img/table.e10626a9.png",
            productPosition : {
              top: 85,
              left: 200
            },
            productId : null
          },
          {
            position : {
              zIndex: 12,
              top: 681,
              left: 715,
              width: 0,
              height: 0
            },
            placeholderImg : "",
            productPosition : {
              top: 200,
              left: 170
            },
            productId : null
          },
          {
            position : {
              zIndex: 12,
              top: 681,
              left: 1105,
              width: 0,
              height: 0
            },
            placeholderImg : "",
            productPosition : {
              top: 185,
              left: 175
            },
            productId : null
          }
        ],
        backgroundAreas : []
      },
      meetingTemplateB: {
        media : {
          mediaContent : [],
          style : {
            position: "absolute",
            left: 71,
            top: 184,
            width: 517,
            height: 281,
            backgroundColor : "#000",
            borderStroke: 3,
            transform : {
              perspective : 507,
              skewY : 5.2,
              skewX : 0,
              rotateY :  14.2,
              rotateX :  0
            }
          }
        },
        productAreas : [
          {
            position : {
              zIndex: 11,
              top: 577,
              left: 319,
              width: 573,
              height: 345
            },
            placeholderImg : "img/plant.1f2ccc8c.png",
            productPosition : {
              top: 250,
              left: 280
            },
            productId : null
          },
          {
            position : {
              zIndex: 10,
              top: 114,
              left: 760,
              width: 528.75,
              height: 702
            },
            placeholderImg : "img/tree.94897c71.png",
            productPosition : {
              top: 650,
              left: 280
            },
            productId : null
          },
          {
            position : {
              zIndex: 11,
              top: 745,
              left: 1309,
              width: 369,
              height: 124.5
            },
            placeholderImg : "img/table.e10626a9.png",
            productPosition : {
              top: 85,
              left: 200
            },
            productId : null
          },
          {
            position : {
              zIndex: 12,
              top: 681,
              left: 715,
              width: 0,
              height: 0
            },
            placeholderImg : "",
            productPosition : {
              top: 200,
              left: 170
            },
            productId : null
          },
          {
            position : {
              zIndex: 12,
              top: 681,
              left: 1105,
              width: 0,
              height: 0
            },
            placeholderImg : "",
            productPosition : {
              top: 185,
              left: 175
            },
            productId : null
          },
          {
            position : {
              zIndex: 12,
              top: 710,
              left: 915,
              width: 0,
              height: 0
            },
            placeholderImg : "",
            productPosition : {
              top: 185,
              left: 175
            },
            productId : null
          }
        ],
        backgroundAreas : [
          {
            position : {
              zIndex: 9,
              top: 756,
              left: 331,
              width: 1248,
              height: 320
            },
            img : ""
          }
        ]
      },
      meetingTemplate: {
        media : {
          mediaContent : [],
          style : {
            position: "absolute",
            left: 71,
            top: 184,
            width: 517,
            height: 281,
            backgroundColor : "#000",
            borderStroke: 3,
            transform : {
              perspective : 507,
              skewY : 5.2,
              skewX : 0,
              rotateY :  14.2,
              rotateX :  0
            }
          }
        },
        productAreas : [
          {
            position : {
              zIndex: 11,
              top: 627,
              left: 319,
              width: 573,
              height: 345
            },
            placeholderImg : "img/plant.1f2ccc8c.png",
            productPosition : {
              top: 220,
              left: 280
            },
            productId : null,
          },
          {
            position : {
              zIndex: 10,
              top: 138,
              left: 773,
              width: 528.75,
              height: 702
            },
            placeholderImg : "img/tree.94897c71.png",
            productPosition : {
              top: 650,
              left: 280
            },
            productId : null
          },
          {
            position : {
              zIndex: 11,
              top: 745,
              left: 1309,
              width: 369,
              height: 124.5
            },
            placeholderImg : "img/table.e10626a9.png",
            productPosition : {
              top: 85,
              left: 200
            },
            productId : null
          },
          {
            position : {
              zIndex: 12,
              top: 681,
              left: 715,
              width: 0,
              height: 0
            },
            placeholderImg : "",
            productPosition : {
              top: 200,
              left: 170
            },
            productId : null
          },
          {
            position : {
              zIndex: 12,
              top: 681,
              left: 1105,
              width: 0,
              height: 0
            },
            placeholderImg : "",
            productPosition : {
              top: 185,
              left: 175
            },
            productId : null
          },
          {
            position : {
              zIndex: 12,
              top: 710,
              left: 915,
              width: 0,
              height: 0
            },
            placeholderImg : "",
            productPosition : {
              top: 185,
              left: 175
            },
            productId : null
          }
        ],
        backgroundAreas : [
        ]
      },
      mediaIsFullscreen: false,
      mediaPDFCurrentPage: 1,
      mediaPDFPageLength: null,
      movingElement: null,
      editor: false,
      auth: false,
      loggedIn: false,
      accessToken: null,
      meetingCreated: null,
      AttendMeetingInvalid: "",
      currentRoomCode: null,
      ApiKey: "55JuMTj7XStFje2EKqjgR8X4jJ8Wh8du",
      loading: false
    }
  },
  computed:
  {
    isInMeeting()
    {
      var validMeetingCode = this.$store.state.meeting.meetingCode;
      var isInMeeting = (this.$route.query.meeting === validMeetingCode);

      return isInMeeting;
    },
    meeting()
    {
      if (this.isInMeeting)
      {
        return this.$store.state.meeting;
      }

      return false;
    }
  },
  created()
  {    
    eventBus.emit('mainNavigation.hide');
  },
  mounted()
  {
    let roomcode = this.$route.query.roomCode
    if(roomcode){
      document.querySelector(".meetingCodeInput").value = roomcode;
    }
  },
  unmounted()
  {
    
  },
  methods:
  {
    copyRoomLink(){
      let newRoomLink = String(window.location).split("?")[0] + "?roomCode=" + this.meetingCreated;
      if (navigator && navigator.clipboard && navigator.clipboard.writeText){
        navigator.clipboard.writeText(newRoomLink);
      }
    },
    goToRoom(){

      let newRoomLink = String(window.location).split("?")[0] + "?roomCode=" + this.meetingCreated;
      if(newRoomLink){
        window.open(newRoomLink, '_blank');
      }
    },
    setMeetingLayout(event){
      switch (event.target.value) {
        case "1":
          this.meetingTemplate = this.meetingTemplateA;
          break;
      
        case "2":
          this.meetingTemplate = this.meetingTemplateB;
          break;

        default:
          break;
      }
    },
    async toggleFullscreenPDF() {
      if(!this.mediaPDFPageLength){
        let blob = await fetch(`https://intellianeventgateway-staging.azurewebsites.net/uploads/`+this.meetingTemplate.media.mediaContent[0]).then(r => r.blob());
        var reader = new FileReader();
        reader.readAsBinaryString(blob);
        var self = this;
        reader.onloadend = function(){
            var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
            self.mediaPDFPageLength = count;
        }
      }
      if(this.mediaIsFullscreen){
        this.mediaIsFullscreen = false;
        eventBus.emit('mainNavigation.unHide');
      }else{
        this.mediaIsFullscreen = true;
        eventBus.emit('mainNavigation.hide');
      }
    },
    pdfnavigatePage(forward) {
      var mediaPDF = document.querySelector(".mediaPDFContainer");
      var mediaPDFFull = document.querySelector(".mediaPDFContainerFull");
      console.log("---PDF NAVIGATE---");
      console.log("Length: ", this.mediaPDFPageLength);
      console.log("Current Page: ", this.mediaPDFCurrentPage);
      if(forward){

        if(this.mediaPDFPageLength > this.mediaPDFCurrentPage){
          console.log("Next Page: ", (this.mediaPDFCurrentPage + 1));
          mediaPDFFull.data = mediaPDFFull.data.replace("page="+ this.mediaPDFCurrentPage, "page=" + (this.mediaPDFCurrentPage + 1));
          mediaPDF.data = mediaPDF.data.replace("page="+ this.mediaPDFCurrentPage, "page=" + (this.mediaPDFCurrentPage + 1));
          this.mediaPDFCurrentPage = this.mediaPDFCurrentPage + 1;
        }
      }else{
        if(this.mediaPDFCurrentPage != 1){
          console.log("Next Page: ", (this.mediaPDFCurrentPage - 1));
          mediaPDFFull.data = mediaPDFFull.data.replace("page="+ this.mediaPDFCurrentPage, "page=" + (this.mediaPDFCurrentPage - 1));
          mediaPDF.data = mediaPDF.data.replace("page="+ this.mediaPDFCurrentPage, "page=" + (this.mediaPDFCurrentPage - 1));
          this.mediaPDFCurrentPage = this.mediaPDFCurrentPage - 1;
        }
      }
      document.querySelector(".mediaPDFContainerOverlay_2").style.backgroundColor = "rgba(0,0,0,1)";
      setTimeout(() => {
        document.querySelector(".mediaPDFContainerOverlay_2").style.backgroundColor = "rgba(0,0,0,0)";
      }, 1000);
      setTimeout(() => {
        var clone = mediaPDFFull.cloneNode(true); 
        var placeHolder = mediaPDFFull.parentNode; 
        placeHolder.removeChild(mediaPDFFull); 
        placeHolder.appendChild(clone); 

        var clone2 = mediaPDF.cloneNode(true); 
        var placeHolder2 = mediaPDF.parentNode; 
        placeHolder2.removeChild(mediaPDF); 
        placeHolder2.appendChild(clone2); 
      }, 100);
    },
    createMeeting(){
      this.loading = true;
      var media = document.querySelector(".productFileInput").files[0];

      var myHeaders = new Headers();
      myHeaders.append("ApiKey", this.ApiKey);
      myHeaders.append("Authorization", "Bearer " + this.accessToken);
      var formdata = new FormData();
      formdata.append("data", JSON.stringify(this.meetingTemplate));
      if(media){
        formdata.append("media", media, media.name);
      }
      fetch("https://intellianeventgateway-staging.azurewebsites.net/api/v1/showroom/rooms", {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      }).then(response => response.text()).then(result => {
        var dataResponse = JSON.parse(result);
        //dataResponse.roomId
        this.meetingCreated = dataResponse.roomId;
        this.loading = false;

      }).catch(error => {
          alert("File size exceeds 25MB limit", error)
          this.loading = false;
        });
    },
    loginCreate(){

      this.loading = true;
      let code = document.querySelector(".meetingSignIn_CodeInput").value;
      var myHeaders = new Headers();
      myHeaders.append("ApiKey", this.ApiKey);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "password": code
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("https://intellianeventgateway-staging.azurewebsites.net/api/v1/showroom/login", requestOptions)
        .then(response => response.text())
        .then(result => {
          result = JSON.parse(result);
          if(result.accessToken && result.accessExpiry){
            this.loggedIn = true;
            this.accessToken = result.accessToken;
            this.auth = true;
            eventBus.emit('mainNavigation.unHide');
            this.AttendMeetingInvalid = " ";
          }else{
            this.AttendMeetingInvalid = "The code is invalid, try again.";
          }
          this.loading = false;
        }).catch(error => {
            this.AttendMeetingInvalid = "The code is invalid, try again.";
            console.log('error', error)
            console.log(this.AttendMeetingInvalid);
            this.loading = false;
        });

      
    },
    loginAttend(){
      this.loading = true;
      let codeValue = document.querySelector(".meetingCodeInput").value;
      let checkRe = /^[A-z0-9]*/g;
      let validInput = checkRe.exec(codeValue);
      if(validInput[0]){
        var myHeaders = new Headers();
        myHeaders.append("ApiKey", this.ApiKey);
        fetch("https://intellianeventgateway-staging.azurewebsites.net/api/v1/showroom/rooms/" + validInput[0], {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        }).then(response => {
          
          if(response.status != 200){
            this.AttendMeetingInvalid = "The code is invalid, try again.";
            this.loading = false;
            return
          }else{
            this.loading = false;
            return response.text();
          }
        }).then(result => {
            this.meetingTemplate = JSON.parse(result);
            this.loggedIn = true;
            eventBus.emit('mainNavigation.unHide');
            this.AttendMeetingInvalid = " ";
            this.loading = false;
        }).catch(error => console.log('error', error));
        }else{
        this.AttendMeetingInvalid = "The code is invalid, try again.";
        this.loading = false;
      }
    },
    setMovingElement(product){
      this.movingElement = product.id;
    },
    allowDrop(event) {
        event.preventDefault();
    },
    drop(event, productArea) {
        event.preventDefault();
        productArea.productId = this.movingElement;
    },
    getProductList(){
      return this.$store.state.products;
    },
    product(productId){
      var result = null;

      this.$store.state.products.forEach((product)=>
      {
        if (product.id === productId) result = product;
      });
      return result;
    },
    mediaUrl(image){
      return getUmbracoMediaItemSrc(image);
    },
    goBack(){
      eventBus.emit('mainNavigation.unHide');
      goToParentScene();
    },
    openVideo(videoData){
      this.popupVideoData = videoData;
    },
    GetPreloadedImage(url){
      return getPreloadedImage(url);
    }
  }
}
</script>