<template>

  <Popup type="content" @close="$emit('close')" :key="product.id">
    <div class="product-specs" :class="{'product-specs--full-model-view-mode': showModelViewerUI}">
      <div class="product-specs__header">
        <div class="product-specs__name">{{product.name}}</div>
        <div class="product-specs__sub-heading">Specifications</div>
      </div>
      <div class="product-specs__box product-specs__box--1">
        <div class="product-specs__box-heading">{{product.data.specPopupBox1Heading}}</div>
        <div v-html="product.data.specPopupBox1Text"></div>
      </div>
      <div class="product-specs__box product-specs__box--2">
        <div class="product-specs__box-heading">{{product.data.specPopupBox2Heading}}</div>
        <div v-html="product.data.specPopupBox2Text"></div>
      </div>
      <div class="product-specs__box product-specs__box--downloads" v-if="(!isOffline || !isMeetingRoom) && (getDownloadLink(product.data.download1File, product.data.download1Url) || getDownloadLink(product.data.download2File, product.data.download2Url))">
        <div class="product-specs__box-heading">Downloads</div>
        <div class="download-links">

          <a v-if="product.data.download1Text && getDownloadLink(product.data.download1File, product.data.download1Url)" class="download-links__link" :class="'download-links__link--'+product.data.download1Icon" target="_blank" :href="getDownloadLink(product.data.download1File, product.data.download1Url)">
            <span>{{product.data.download1Text}}</span>
          </a>

          <a v-if="product.data.download2Text && getDownloadLink(product.data.download2File, product.data.download2Url)" class="download-links__link" :class="'download-links__link--'+product.data.download2Icon" target="_blank" :href="getDownloadLink(product.data.download2File, product.data.download2Url)">
            <span>{{product.data.download2Text}}</span>
          </a>

        </div>
      </div>

      <div class="product-specs__box product-specs__box--qrCodes" v-if="(product.data.qrCode1 || product.data.qrCode2) && (isOffline || isMeetingRoom)">
        <div v-if="product.data.qrCode1">
          <p>{{product.data.qrCode1Title}}</p>
          <img :src="mediaUrl(product.data.qrCode1)">
        </div>
        <div v-if="product.data.qrCode2">
          <p>{{product.data.qrCode2Title}}</p>
          <img :src="mediaUrl(product.data.qrCode2)">
        </div>
        <div class="product-specs__box--qrZoom" @click="showQR(true)"></div>
      </div>
      
      <div class="product--qrBig" v-if="product.data.qrCode1 && showQrBig || product.data.qrCode2 && showQrBig ">
        <div class="qrImageContainer">
          <div class="qrImage" v-if="product.data.qrCode1" :class="[!product.data.qrCode2 ? 'singleBlock' : '']" >
            <p>{{product.data.qrCode1Title}}</p>
            <img :src="mediaUrl(product.data.qrCode1)">
          </div>
          <div class="qrImage" v-if="product.data.qrCode2" :class="[!product.data.qrCode1 ? 'singleBlock' : '']">
            <p>{{product.data.qrCode2Title}}</p>
            <img :src="mediaUrl(product.data.qrCode2)">
          </div>
          <div class="qrClose" @click="showQR(false)"></div>
        </div>
      </div>

      <div class="product-specs__box product-specs__box--3">
        <div class="product-specs__box-heading">{{product.data.specPopupBox3Heading}}</div>
        <div v-html="product.data.specPopupBox3Text"></div>
      </div>

      <div class="product-specs__box product-specs__box--4">
        <div class="product-specs__box-heading">{{product.data.specPopupBox4Heading}}</div>
        <div v-html="product.data.specPopupBox4Text"></div>
      </div>

      <div class="product-specs__scale">

        <div class="product-specs__scale-img-frame"
        :class="[{'product-specs__scale-img-frame--loading-3d': product.data.show3DModel && !modelLoaded}, {'product-specs__scale-img-frame--loaded': product.data.show3DModel && modelLoaded}]"
        :style="{bottom: -product.data.productImageSpaceBelowInPixels+'px'}">
          <PreloadedImage v-if="product.data.specPopupScaleImage" class="product-specs__scale-image" :src="mediaUrl(product.data.specPopupScaleImage)"/>
        </div>

        <div class="product-specs__scale-unit product-specs__scale-unit--width">
          <span v-if=" unit === 'metric'">{{product.data.scaleWidthCentimeters}} cm</span>
          <span v-if=" unit === 'imperial'">{{product.data.scaleWidthInches}} inches</span>
        </div>
        <div class="product-specs__scale-unit product-specs__scale-unit--height">
          <span  v-if=" unit === 'metric'">{{product.data.scaleHeightCentimeters}} cm</span>
          <span  v-if=" unit === 'imperial'">{{product.data.scaleHeightInches}} inches</span>
        </div>
      </div>

      <div class="product-specs__scale-unit-toggle toggle-button">
        <div class="toggle-button__value" :class="{'toggle-button__value--selected': unit === 'metric' }" @click="setUnit('metric')">Metric</div>
        <div class="toggle-button__value" :class="{'toggle-button__value--selected': unit === 'imperial' }" @click="setUnit('imperial')">Imperial</div>
      </div>

      <div class="product-specs__3d-view" v-if="product.data.show3DModel && product.data.product3DModel">

        <ModelViewer :src="product3DModel" :distance="initialModelDistanceAsNumber" @loaded="onModelLoad" @onWheel="handleWheel" @onDrag="handleDrag"/>

      </div>

      <div class="product-specs__3d-loading" v-if="product.data.show3DModel && !modelLoaded"></div>

      <div class="product-specs__3d-instructions" v-if="product.data.show3DModel && modelLoaded && !modelHasBeenDragged">
        <span>Use mouse</span>
        <span></span>
        <span></span>
      </div>

      <div class="product-specs__3d-viewer-header">
        <div class="product-specs__name product-specs__name--small">{{product.name}}</div>
        <Button class="button--icon-plus button--flipped button--inline" @click="resetModelView">Show specifications</Button>
      </div>

      <div class="product-specs__3d-controls" v-if="modelLoaded && model">
        
        <div class="product-specs__3d-controls-title">Radome</div>

        <div class="toggle-button">
          <div class="toggle-button__value" :class="{'toggle-button__value--selected': !radomeHidden}" @click="viewerShowRadome">On</div>
          <div class="toggle-button__value" :class="{'toggle-button__value--selected': radomeHidden}" @click="viewerHideRadome">Off</div>
        </div>

      </div>

    </div>

  </Popup>

</template>

<script>
import Popup from '@/components/Popup';
import PreloadedImage from '@/components/PreloadedImage';
import ModelViewer from '@/components/ModelViewer';
import Button from '@/components/Button';
import eventBus from '@/helpers/eventBus';
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';

export default {
  name: 'ProductSpecsPopup',
  components: {Popup, PreloadedImage, ModelViewer, Button},
  props: {
    product: Object
  },
  data()
  {
    return {
      unit: this.$store.state.measuringUnit,

      // Model Viewer
      modelLoaded: false,
      model: null,
      omvRef: {omv: null},
      modelHasBeenDragged: false,
      showModelViewerUI: false,
      radomeHidden: false,
      showQrBig: false
    };
  },
  computed:
  {
    isMeetingRoom(){
      let meetingRoom = document.querySelector(".meeting-room");
      if(meetingRoom){
        return true;
      }else{
        return false;
      }
    },
    isOffline()
    {
      /* global isElectron */
      return (typeof(isElectron) !== "undefined" && isElectron);
    },
    initialModelDistanceAsNumber()
    {
      var result = parseFloat(this.product.data.initialModelDistance);
      if (isNaN(result))
      {
        result = 0;
      }
      return result;
    },
    product3DModel()
    {
      if (!this.product.data.product3DModel)
      {
        return;
      }
      return getUmbracoMediaItemSrc(this.product.data.product3DModel);
    },
  },
  methods:
  {
    showQR(toggle){
      this.showQrBig = toggle;
    },
    setUnit(unit)
    {
      this.$store.commit('setMeasuringUnit', unit);
    },
    getDownloadLink(downloadFile, downloadUrl)
    {
      var result = '';
      if (downloadUrl) 
      {
        result = downloadUrl;
      }  
      if (downloadFile)
      {
        result = getUmbracoMediaItemSrc(downloadFile)
      }
  
      return result;
    },
    mediaUrl(image)
    {
      return getUmbracoMediaItemSrc(image);
    },
    viewerShowRadome()
    {
      this.radomeHidden = false;
      this.resetMaterials();
    },
    viewerHideRadome()
    {
      this.radomeHidden = true;
      this.model.materialGroups.forEach((materialGroup)=>{
        this.changeMaterial(materialGroup.matGrp);
      });
    },
    handleWheel()
    {
      this.showModelViewerUI = true;
    },
    handleDrag()
    {
      this.modelHasBeenDragged = true;
      this.showModelViewerUI = true;
    },
    resetModelView()
    {
      // this.modelHasBeenDragged = false;
      this.showModelViewerUI = false;
      this.radomeHidden = false;

      // NOTE: Reset model 
      this.modelLoaded = false;
      eventBus.emit('modelViewer.resetModel');
    },
    onModelLoad(model, omv)
    {
      this.modelLoaded = true;
      this.model = model;
      this.omvRef.omv = omv;
    },
    resetMaterials()
    {
      if (!this.omvRef.omv) return;
      this.omvRef.omv.resetMaterialsForModel();
    },
    changeMaterial(materialGroupName)
    {
      if (!this.omvRef.omv) return;
      var materialName = 'Hidden';
      this.omvRef.omv.changeMaterialForMaterialGroup(materialName, materialGroupName);
    },
  },
  created()
  {
    this.modelLoaded = false;
    this.modelHasBeenDragged = false;
    this.showModelViewerUI = false;
    this.radomeHidden = false;

    this.unsubscribe = this.$store.subscribe((mutation, state) =>
    {
      if (mutation.type === 'setMeasuringUnit')
      {
        this.unit = state.measuringUnit;
      }
    });
  },
  unmounted()
  {
    this.unsubscribe();
  }
}
</script>