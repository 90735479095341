<template>
  <Popup type="content" @close="$emit('close')">
    <div class="iframe-in-popup">
        <iframe :src="iframeSrc" width="100%" height="500" type="text/html" frameborder="0" allowTransparency="true" :style="customStyle"></iframe>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup';

export default {
  name: 'IframeInPopup',
  components: {Popup},
  props: {
    iframeSrc: String,
    customStyle: String
  },
  data()
  {
    return {}
  },
  created()
  {},
  methods:
  {
  }
}
</script>
