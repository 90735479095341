<template>

    <!-- NOTE: We manually map data to vue components here -->

    <ContentPopup v-if="contentComponentData.type === 'contentPopup'" :tabs="contentComponentData.data.tabs" @close="closeContentComponent"/>

    <IframeInPopup v-if="contentComponentData.type === 'iframeInPopup'" :customStyle="contentComponentData.data.customStyle" :iframeSrc="contentComponentData.data.source" @close="closeContentComponent"/>

</template>

<script>
import ContentPopup from '@/components/content-components/ContentPopup';
import IframeInPopup from '@/components/content-components/IframeInPopup';
import {closeComponentIfOpen} from '@/helpers/navigationHelper';

export default {
  name: 'ContentComponentRenderer',
  components: {
    ContentPopup,
    IframeInPopup
  },
  props: {
      contentComponentData: Object
  },
  created()
  {
  },
  methods:
  {
    closeContentComponent()
    {
      closeComponentIfOpen();
    }
  }
}
</script>
