<template>
    <div class="logo" :style="css" :class="'logo--style-'+style" @click="goToHomePage"></div>
</template>

<script>
import {goToPath} from '@/helpers/navigationHelper';

export default {
  name: 'Logo',
  props:
  {
      css: String,
      style: String
  },
  methods:
  {
    goToHomePage()
    {
      goToPath('/');
    }
  }
}
</script>