<template>
  <div class="basic-video">

    <div
        v-if="videoType === 'iframe'"
        class="basic-video__iframe-wrapper"
        v-html="videoEmbedCodeFromURL">
    </div>
    <div
        v-if="videoType === 'html5'"
        class="basic-video__html5-wrapper">
        <video
            ref="html5Video"
            :src="getMediaSrc(videoMediaFile)"
            :controls="controls"
            :muted="muted"
            :autoplay="autoplay"
            :loop="loop"
            playsinline>
        </video>
    </div>
  </div>
</template>

<script>
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';

export default {
    name: 'BasicVideo',
    props: {
        videoMediaFile: Object,
        videoLink: String,
        controls: Boolean,
        autoplay: Boolean,
        loop: Boolean,
        muted: Boolean,
        active: Boolean
    },
    data() {
        return {}
    },
    watch: {
        active(value)
        {
            if (this.videoType === 'html5')
            {
                if (value)
                {
                    // NOTE: Start the video
                    this.$refs.html5Video.play();
                }
                else
                {
                    // NOTE: Stop the video
                    this.$refs.html5Video.pause();
                    // this.$refs.html5Video.currentTime = 0; // NOTE: Enable this if the video should reset when interrupted.
                }
            }
            else
            {
                // NOTE: Starting/stopping video currently not implemented for external videos. (youtube, vimeo)
            }
        }
    },
    computed:
    {
        videoType()
        {
            if (this.videoMediaFile)
            {
                return 'html5';
            }
            else if (this.videoLink)
            {
                return 'iframe'
            }

            return false;
        },
        videoEmbedCodeFromURL()
        {
            var link = this.videoLink;

            var isVimeo = this.videoLink.indexOf('vimeo') !== -1;
            var isYoutube = this.videoLink.indexOf('youtube') !== -1;
            var iframeMarkup = null;

            if (isVimeo)
            {
                // NOTE: Generate vimeo embed markup
                var match = /vimeo.*\/(\d+)/i.exec(link);
                var vimeoVideoId = null;

                if (match)
                {
                    vimeoVideoId = match[1];
                }

                if (vimeoVideoId)
                {
                    iframeMarkup = `<iframe 
                        src="https://player.vimeo.com/video/`+vimeoVideoId+`?autoplay=1"
                        frameborder="0"
                        allow="autoplay"
                        allowfullscreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen" 
                        msallowfullscreen="msallowfullscreen" 
                        oallowfullscreen="oallowfullscreen" 
                        webkitallowfullscreen="webkitallowfullscreen"
                    ></iframe>`;

                    return iframeMarkup;
                }
            }
            else if (isYoutube)
            {
                // NOTE: Generate youtube embed markup
                var youtubeVideoId = link.split('v=')[1];
                var ampersandPosition = youtubeVideoId.indexOf('&');
                if (ampersandPosition != -1)
                {
                    youtubeVideoId = youtubeVideoId.substring(0, ampersandPosition);
                }

                iframeMarkup = `<iframe 
                    src="https://www.youtube.com/embed/`+youtubeVideoId+`?modestbranding=1&showinfo=0&autoplay=1&autohide=1&rel=0"
                    frameborder="0"
                    allow="autoplay"
                    allowfullscreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen" 
                    msallowfullscreen="msallowfullscreen" 
                    oallowfullscreen="oallowfullscreen" 
                    webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>`;

                return iframeMarkup;
            }

            return '';
        },
    },
    mounted()
    {
        // NOTE: Start playing if active initially
        if (this.videoType === 'html5' && this.active)
        {
            this.$refs.html5Video.play();    
        }
    },
    methods: {
        getMediaSrc(mediaItem) {
            return getUmbracoMediaItemSrc(mediaItem);
        }
    }
}
</script>