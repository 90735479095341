<template>

  <div class="category-menu" :class="{'category-menu--is-showcase': category.data.isShowcase}">

    <div class="category-menu__category-info" v-if="category">
      <div class="category-menu__category-title">{{category.data.categoryName}}</div>
      <div class="category-menu__category-description" v-html="category.data.description"></div>

      <Button v-if="category.data.isShowcase" @click="goBack" class="button--flipped">Back to Atrium</Button>

    </div>

    <div class="category-menu__products" v-if="!category.data.isShowcase">

      <div class="category-menu__products-arrow category-menu__products-arrow--up" :class="{'category-menu__products-arrow--disabled': !canScrollUpProducts}" @click="scrollUpProducts()"></div>
      <div class="category-menu__products-arrow category-menu__products-arrow--down" :class="{'category-menu__products-arrow--disabled': !canScrollDownProducts}" @click="scrollDownProducts()"></div>

      <div class="category-menu__products-scroll" ref="categoryScroll">

        <div class="category-menu__products-scroll-inner">

          <template v-for="(product,i) in productsInCategory" :key="i">
            <div class="category-menu__product">
              <router-link :to="{path: scenePath + product.slug + '/', query: $route.query}" class="category-menu__product-link">
                <PreloadedImage v-if="product.data.productIcon" class="category-menu__product-image" :src="mediaUrl(product.data.productIcon)"/>
                <div class="category-menu__product-title"><span>{{product.name}}</span></div>
              </router-link>
            </div>
          </template>

        </div>

      </div>

    </div>

  </div>

  <div v-if="!category.data.isShowcase" class="hotspot-button hotspot-button--icon-arrow hotspot-button--direction-left" style="left: 376px; top: 695px;" @click="goBack">
    <div class="hotspot-button__inner">
      <div class="hotspot-button__icon" style="animation-duration: 16000ms; animation-delay: 16000ms;"></div>
      <div class="hotspot-button__title">Product Showroom</div>
    </div>
  </div>

  <div class="category-media" v-if="product && mediaSlidesFilteredByCategory" :key="product.id">
    <Slider v-if="mediaSlidesFilteredByCategory" :slides="mediaSlidesFilteredByCategory" style-variant="default" @openVideo="openVideo"/>
  </div>

  <template v-if="product">

    <div class="product-display" :key="product.id">
      <PreloadedImage v-if="product.data.productImage" class="product-display__image" :src="mediaUrl(product.data.productImage)" :style="{bottom: -product.data.productImageSpaceBelowInPixels+'px'}"/>
    </div>

    <div class="product-info" :key="product.id">
      <div class="product-info__name">{{product.name}}</div>
      <div class="product-info__description" v-html="product.data.description"></div>

      <Button @click="openSpecsPopup" class="button--flipped product-info__button button--icon-plus">Specifications</Button>

    </div>

    <div class="scale-chart-small" :key="product.id" v-if="product.data.productScaleOutlineImage && !product.data.hideScaleChart">
      <div class="scale-chart-small__product" :style="{left: product.data.productScaleOutlineImageOffsetX+'px'}">
        <PreloadedImage v-if="product.data.productScaleOutlineImage" class="scale-chart-small__image" :src="mediaUrl(product.data.productScaleOutlineImage)"/>
      </div>
    </div>

  </template>

  <ProductSpecsPopup v-if="specsPopupIsOpen" :product="product" @close="closeSpecsPopup"/>

  <Popup type="video" v-if="popupVideoData" @close="popupVideoData = null">
    <BasicVideo
      :videoMediaFile="popupVideoData.videoMediaFile"
      :videoLink="popupVideoData.videoLink"
      :controls="true"
      :loop="false"
      :autoplay="true"
      :muted="false"/>
  </Popup>

</template>

<script>
import eventBus from '@/helpers/eventBus';
import Slider from '@/components/Slider';
import Popup from '@/components/Popup';
import BasicVideo from '@/components/BasicVideo';
import Button from '@/components/Button';
import {goToParentScene} from '@/helpers/navigationHelper';
import PreloadedImage from '@/components/PreloadedImage';
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import ProductSpecsPopup from '@/components/ProductSpecsPopup';

export default {
  name: 'ProductsView',
  components: {Slider, /*ModelViewer,*/ Popup, BasicVideo, Button, PreloadedImage, ProductSpecsPopup},
  props: {
    scenePath: String,
    categoryId: Number
  },
  data()
  {
    return {
        products: [],
        productCategories: [],
        popupVideoData: null,
        
        specsPopupIsOpen: false,

        scrollHeight: 0,
        scrollTop: 0,
        scrollPageHeight: 0,
        isScrolling: false,
    }
  },
  computed:
  {
    canScrollUpProducts()
    {
      return (this.scrollTop > 0);
    },
    canScrollDownProducts()
    {
      return (this.scrollTop + this.scrollPageHeight < this.scrollHeight /*Note padding*/ - 250);
    },
    productsInCategory()
    {
      var productsFound = [];

      this.products.forEach((product)=>{

        if (product.categories && product.categories.indexOf(this.categoryId) !== -1)
        {
          productsFound.push(product);
        }

      });

      // NOTE: Sort by size. Large to small
      productsFound.sort((a, b)=>{
        if (a.data.scaleValue > b.data.scaleValue)
        {
          return -1;
        }
        if (a.data.scaleValue < b.data.scaleValue)
        {
          return 1;
        }
        // a must be equal to b
        return 0;
      });      

      return productsFound;
    },
    product()
    {
      var result = null;

      if (this.$route.params.productSlug)
      {
        this.products.forEach((product)=>
        {
          if (product.slug === this.$route.params.productSlug)
          {
            result = product;
          }
        });
      }

      return result;
    },
    category()
    {
      var result = null;

      this.productCategories.forEach((category)=>{
        if (category.id === this.categoryId)
        {
          result = category;
        }
      });

      return result;

    },
    mediaSlidesFilteredByCategory()
    {
      var result = [];
      
      if (this.product && this.product.data && this.product.data.mediaSlides && this.product.data.mediaSlides.length)
      {
        this.product.data.mediaSlides.forEach((slide)=>{

          // NOTE: Only push slide if matching category (Or if no category is defined)
          if (slide.limitToTheseCategories && slide.limitToTheseCategories.length)
          {
            var categoryMatches = false;
            slide.limitToTheseCategories.forEach((categoryId)=>{
              if (this.categoryId === categoryId)
              {
                categoryMatches = true;
              }
            });
            if (categoryMatches)
            {
              result.push(slide);
            }
          }
          else
          {
            result.push(slide);
          }
        });
      }

      return result
    }
  },
  created()
  {
    this.products = this.$store.state.products;
    this.productCategories =  this.$store.state.productCategories;

    this.redirectToFirstProductIfNeeded(this.$route);

    eventBus.emit('mainNavigation.hideBecauseOfShowroom');
  },
  mounted()
  {
    if (this.$refs.categoryScroll)
    {
      this.doScrollAreaCalculations = ()=>{
        this.scrollHeight = this.$refs.categoryScroll.scrollHeight;
        this.scrollTop = this.$refs.categoryScroll.scrollTop;
        this.scrollPageHeight = this.$refs.categoryScroll.clientHeight;

        window.clearTimeout(this.isScrolling);
        this.isScrolling = setTimeout(()=>{
          this.snapScrollToNearestProduct();
        }, 66);
      }
      this.doScrollAreaCalculations(); // NOTE: Grab initial sizes

      this.$refs.categoryScroll.addEventListener('scroll', this.doScrollAreaCalculations);
    }
  },
  unmounted()
  {    
    eventBus.emit('mainNavigation.unHideBecauseOfShowroom');

    if (this.$refs.categoryScroll)
    {
      this.$refs.categoryScroll.removeEventListener('scroll', this.doScrollAreaCalculations);
      if (this.isScrolling)
      {
        window.clearTimeout(this.isScrolling);
      }
    }
  },
  watch: {
		$route(to) {
      this.redirectToFirstProductIfNeeded(to);
		}
	},
  methods: 
  {
    getScrollProducts()
    {
      var result = [];

      var productElements = this.$refs.categoryScroll.querySelectorAll('.category-menu__product');

      var scrollPos = this.$refs.categoryScroll.scrollTop;
      var productClosestToScrollTop = false;

      productElements.forEach((productElement)=>
      {
        var item = {
          isClosestToScrollTop: false,
          distanceToScrollTop: Math.abs(scrollPos - productElement.offsetTop),
          position: productElement.offsetTop,
          element: productElement,
        }

        result.push(item);

        if (!productClosestToScrollTop || item.distanceToScrollTop < productClosestToScrollTop.distanceToScrollTop)
        {
          productClosestToScrollTop = item;
        }

      });

      if (productClosestToScrollTop)
      {
        productClosestToScrollTop.isClosestToScrollTop = true;
      }

      return result;
    },
    scrollUpProducts()
    {
      var scrollProducts = this.getScrollProducts();

      var productToScrollTo = null;
      scrollProducts.forEach((scrollProduct, i)=>{
        if (scrollProduct.isClosestToScrollTop)
        {
          if (i > 0)
          {
            productToScrollTo = scrollProducts[i-1];
          }
        }
      });

      this.$refs.categoryScroll.scrollTo({
        top: productToScrollTo.position - 22,
        left: 0,
        behavior: 'smooth'
      });
    },
    scrollDownProducts()
    {
      var scrollProducts = this.getScrollProducts();

      var productToScrollTo = null;
      scrollProducts.forEach((scrollProduct, i)=>{
        if (scrollProduct.isClosestToScrollTop)
        {
          if (scrollProducts.length >= i)
          {
            productToScrollTo = scrollProducts[i+1];
          }
        }
      });

      this.$refs.categoryScroll.scrollTo({
        top: productToScrollTo.position - 22,
        left: 0,
        behavior: 'smooth'
      });
    },
    snapScrollToNearestProduct()
    {
      // NOTE: Snap to top visible product.... but we HAVE to make sure the lst ones can be viewed too... allow scrolling way down?
      var scrollProducts = this.getScrollProducts();

      scrollProducts.forEach((scrollProduct)=>{
        if (scrollProduct.isClosestToScrollTop)
        {
          this.$refs.categoryScroll.scrollTo({
            top: scrollProduct.position - 22,
            left: 0,
            behavior: 'smooth'
          });
        }
      });
    },
    mediaUrl(image)
    {
      return getUmbracoMediaItemSrc(image);
    },
    redirectToFirstProductIfNeeded(route)
    {
      // NOTE: Redirect to first product in category if not already on a product route...
      if (!route.params.productSlug)
      {
        var productToRedirectTo = null;

        if (this.productsInCategory.length)
        {
           productToRedirectTo = this.productsInCategory[0];
        }

        if (productToRedirectTo)
        {
          this.$router.replace({path: this.scenePath + productToRedirectTo.slug+'/', query: this.$route.query});
        }
      }
    },
    openVideo(videoData)
    {
      this.popupVideoData = videoData;
    },
    openSpecsPopup()
    {
      this.specsPopupIsOpen = true;
    },
    closeSpecsPopup()
    {
      this.specsPopupIsOpen = false;
    },
    goBack()
    {
      goToParentScene();
    }
  }
}
</script>