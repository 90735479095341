<template>
    <div class="popup" :class="'popup--style-'+type">
        <div class="popup__window">
            <slot></slot>
            <div class="popup__close-btn" @click="$emit('close')"></div>
        </div>
        <div class="popup__overlay" @click="$emit('close')"></div>
    </div>
</template>

<script>
import eventBus from '@/helpers/eventBus';

export default {
  name: 'Popup',
  props: {
      type: String
  },
  created()
  {
    eventBus.emit('mainNavigation.hide');
  },
  unmounted()
  {
    eventBus.emit('mainNavigation.unHide');
  }
}
</script>