<template>
  <transition name="fade" appear>
    <div class="hotspot-button" v-if="!hideIfConfiguredToDoSo"
    :class="[
        {'_hotspot-button--has-transition': hasTransition},
        {'hotspot-button--has-pulse': hasPulse},
        'hotspot-button--icon-'+icon,
        'hotspot-button--direction-'+direction,
    ]"
    :style="['left:'+x+'px;top:'+y+'px;', '_transition-delay: '+transitionDelayMs+'ms']">
      <div
        v-if="this.targetId"
        class="hotspot-button__inner"
        @click="onClick">
        <div class="hotspot-button__icon" :style="['animation-duration: '+pulseIntervalMs+'ms', 'animation-delay: '+pulseIntervalMs+'ms']"></div>
        <div class="hotspot-button__title">{{title}}</div>
      </div>
      <a
        v-else-if="this.externalUrl"
        class="hotspot-button__inner"
        :href="this.externalUrl"
        target="_blank">
        <div class="hotspot-button__icon" :style="['animation-duration: '+pulseIntervalMs+'ms', 'animation-delay: '+pulseIntervalMs+'ms']"></div>
        <div class="hotspot-button__title">{{title}}</div>
      </a>
    </div>
  </transition>
</template>

<script>
import {goToPathByID} from '@/helpers/navigationHelper';


export default {
  name: 'HotspotButton',
  props:
  {
      title: String,
      x: Number,
      y: Number,
      targetPath: String,
      targetId: Number,
      externalUrl: String,
      hasTransition: Boolean,
      transitionDelayMs: Number,
      hasPulse: Boolean,
      pulseIntervalMs: Number,
      icon: String,
      direction: String,
      hideIfOffline: Boolean,
      hideIfNotInMeeting: Boolean,
      hideIfInMeeting: Boolean
  },
  computed:
  {
    hideIfConfiguredToDoSo()
    {
      // NOTE: hideIfOffline
      /* global isElectron */
      var isOffline = (typeof(isElectron) !== "undefined" && isElectron);
      if (this.hideIfOffline && isOffline)
      {
        return true;
      }

      // NOTE: Find out if we are in a meeting
      var validMeetingCode = this.$store.state.meeting.meetingCode;
      var isInMeeting = (this.$route.query.meeting === validMeetingCode);

      // NOTE: hideIfNotInMeeting
      if (this.hideIfNotInMeeting && !isInMeeting)
      {
        return true;
      }

      // NOTE: hideIfInMeeting
      if (this.hideIfInMeeting && isInMeeting)
      {
        return true;
      }

      return false;
    },
  },
  methods:
  {
    onClick()
    {
      if (this.targetId)
      {
          var transformOrigin = (this.x / 1920)*100+'% ' + (this.y / 1080)*100+'%';
          this.$store.commit('setSceneTransitionEffect', {type: 'zoomAndFade', transformOrigin: transformOrigin});

          goToPathByID(this.targetId);
      }
    }
  }
}
</script>
