import { createStore } from 'vuex'
import config from '@/config';
import { preloadImages, getUmbracoMediaItemSrc } from '@/helpers/assetHelper';

export default createStore({

  state:
  {
    manifestWasLoaded: false,
    isLoading: false,
    scenes: [],
    contentComponents: [],
    products: [],
    productCategories: [],
    meeting: {},
    sceneTransitionEffect: {type: 'none'},
    measuringUnit: 'metric' // metric/imperial
  },
  
  mutations:
  {
    setLoadingState(state, payload)
    {
      state.isLoading = payload;
    },

    setMeasuringUnit(state, payload)
    {
      state.measuringUnit = payload;
    },

    setSceneTransitionEffect(state, payload)
    {
      state.sceneTransitionEffect = {
        type: payload.type,
        transformOrigin: payload.transformOrigin
      }
    },

    attatchSortedManifestData (state, payload)
    {
      var sortedManifest = payload;

      state.scenes = sortedManifest.scenes;
      state.contentComponents = sortedManifest.contentComponents;
      state.products = sortedManifest.products;
      state.productCategories = sortedManifest.productCategories;
      state.meeting = sortedManifest.meeting;
    }
  },

  actions:
  {
    loadManifest(context)
    {
      context.commit('setLoadingState', true);

      var manifestPath = config.manifestPath;

      var fetchManifestPromise = fetch(manifestPath, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'same-origin',
        headers: {
            // 'Content-Type': 'application/json'
        },
      })
      .then(
        (response) => {
            return response.json();
        }
      )


      fetchManifestPromise.then(
        (data) => {
          var manifestData = data;
          var sortedManifest = sortManifest(manifestData);

          // NOTE: Add a minimum delay to ensure the loading screen dosen't "flash" awkwardly on screen when loading happens instantly.
          var delayPromise = new Promise((resolve/*, reject*/)=>{
            setTimeout(() => {
                resolve();
            }, 500);
          });

          // NOTE: Preload images here
          var imageUrlsToPreload = [];
          sortedManifest.scenes.forEach((scene)=>{
            if (scene.data && scene.data.backgroundImage)
            {
              var imageSrc = getUmbracoMediaItemSrc(scene.data.backgroundImage);
              imageUrlsToPreload.push(imageSrc);
            }
          });
          sortedManifest.products.forEach((product)=>{
            if (product.data.productImage)
            {
              imageUrlsToPreload.push(getUmbracoMediaItemSrc(product.data.productImage));
            }
            if (product.data.productIcon)
            {
              imageUrlsToPreload.push(getUmbracoMediaItemSrc(product.data.productIcon));
            }
            if (product.data.productScaleOutlineImage)
            {
              imageUrlsToPreload.push(getUmbracoMediaItemSrc(product.data.productScaleOutlineImage));
            }
            if (product.data.specPopupScaleImage)
            {
              imageUrlsToPreload.push(getUmbracoMediaItemSrc(product.data.specPopupScaleImage));
            }
          })

          var preloadImagesPromise = preloadImages(imageUrlsToPreload, true);
          Promise.all([preloadImagesPromise, delayPromise])
            .then(()=>{
              context.commit('attatchSortedManifestData', sortedManifest);
              context.commit('setLoadingState', false);
            });

        });
    }
  }

});

// NOTE: Iterate the manifest data and map it to a more convenient structure
function sortManifest(manifestData)
{
  var sortedManifest = {
    scenes: [],
    contentComponents: [],
    products: [],
    productCategories: [],
    meeting: {}
  };

  var expoRoot = null;
  if (manifestData.custom && manifestData.custom.length > 0 && manifestData.custom[0].type === 'expoSite')
  {
    expoRoot = manifestData.custom[0];
  }
  else
  {
    // TODO: Throw an error
  }
  
  expoRoot.Children.forEach((node)=>{

    // NOTE: Get scenes
    if (node.type === 'sceneRoot')
    {
      var scenes = []
      var contentComponents = [];
      recursivelySortScenes(node, null, scenes, contentComponents);

      sortedManifest.scenes = scenes;
      sortedManifest.contentComponents = contentComponents;
    }

    else if (node.type === 'meeting')
    {
      sortedManifest.meeting = node.data;
    }

    // NOTE: Get products
    else if (node.type === 'products')
    {
      var products = [];

      if (node.Children)
      {
        node.Children.forEach((childNode)=>{

          if (childNode.type === 'product')
          {
            products.push({
              id: childNode.id,
              slug: childNode.data.slug,
              name: childNode.data.productName,
              categories: childNode.data.categories,
              data: childNode.data // NOTE: Just add all remaining data here
            });
          }
          else if (childNode.type === 'productCategories')
          {
            sortedManifest.productCategories = childNode.Children;
          }

        });
      }

      sortedManifest.products = products;
    }

  });

  return sortedManifest;
}

function recursivelySortScenes(node, parentScene, arrayOfScenes, arrayOfContentComponents)
{  
  var scene = {
    id: node.id,
    parentId: (parentScene ? parentScene.id : null),
    slug: node.data.slug,
    path: (parentScene ? (parentScene.path + node.data.slug + '/') : '/'),
    title: node.data.title,
    type: node.type,
    sceneComponents: node.data.sceneComponents,
    data: node.data // NOTE: Just add all remaining data here
  };
  arrayOfScenes.push(scene);

  if (node.Children)
  {
    node.Children.forEach((childNode)=>{
      if (childNode.type == "scene" || childNode.type == "productsScene" || childNode.type == "meetingScene")
      {
        recursivelySortScenes(childNode, scene, arrayOfScenes, arrayOfContentComponents);
      }
      // NOTE: Assume everything else is a content component
      else
      {
        var contentComponent = {
          id: childNode.id,
          scene: scene,
          slug: childNode.data.slug,
          path: scene.path + childNode.data.slug + '/',
          type: childNode.type,
          data: childNode.data // NOTE: Just add all remaining data here
        }
        arrayOfContentComponents.push(contentComponent);
      }
    });
  }

  return arrayOfScenes;
}