<template>
  <AspectRatio>
    
    <transition name="fade" mode="out-in">
      <LoadingScreen v-if="isLoading"></LoadingScreen>
    </transition>

    <router-view></router-view>

  </AspectRatio>
</template>


<script>
import './scss/main.scss';
import AspectRatio from '@/components/AspectRatio';
import LoadingScreen from '@/components/LoadingScreen';

export default {
  name: 'App',
  components:
  {
    AspectRatio: AspectRatio,
    LoadingScreen: LoadingScreen
  },
  data()
  {
    return {}
  },
  computed: 
  {
    isLoading()
    {
      return this.$store.state.isLoading;
    }
  },
  created()
  {
    // NOTE: Tell our Vuex store to load the content
    // TODO: Maybe rename to initialload? Since it might load a lot more than just the manifest...
    this.$store.dispatch('loadManifest');
  }
}
</script>