<template>
    <div class="main-navigation" :class="{'main-navigation--disabled': (isHidden || isHiddenBecauseOfShowroom)}">
        <!-- TODO: Use router link here! -->
        <div 
            class="main-navigation__back-btn" 
            :class="{'main-navigation__back-btn--is-active': (breadcrumbs.length > 1)}"
            @click="goBack">Back</div>
        <div class="main-navigation__breadcrumbs">
            <!-- TODO: Use router link here! -->
            <transition-group name="fade">
              <div
                  v-for="breadcrumb in breadcrumbs"
                  :key="breadcrumb.id"
                  class="main-navigation__breadcrumb"
                  :class="{'main-navigation__breadcrumb--disable': (breadcrumb.preventClick)}"
                  @click="goToPathByID(breadcrumb.id)">
                  <span>{{breadcrumb.title}}</span>
              </div>
            </transition-group>
        </div>
        <div class="main-navigation__title">Intellian World</div>
        <div class="main-navigation__button main-navigation__button--fullscreen" @click="toggleFullscreen"></div>
    </div>
</template>


<script>
import {goToParentScene, goToPathByID, getBreadcrumbs} from '@/helpers/navigationHelper';
import toggleFullscreen from '@/helpers/fullscreenHelper';
import eventBus from '@/helpers/eventBus';

export default {
  name: 'MainNavigation',
  data()
  {
    return {
      isHidden: false,
      isHiddenBecauseOfShowroom: false,
    }
  },
  computed:
  {
    isLoading()
    {
      return this.$store.state.isLoading;
    },
    breadcrumbs()
    {
      return getBreadcrumbs();
    }
  },
  methods:
  {
    goBack()
    {
      goToParentScene();
    },
    goToPathByID(id)
    {
        goToPathByID(id);
    },
    toggleFullscreen()
    {
        toggleFullscreen();
    }
  },
  created()
  {
    this.events = {
      onHide: ()=>{
        this.isHidden = true;
      },
      onUnHide: ()=>{
        this.isHidden = false;
      },
      onHideBecauseOfShowroom: ()=>{
        this.isHiddenBecauseOfShowroom = true;
      },
      onUnHideBecauseOfShowroom: ()=>{
        this.isHiddenBecauseOfShowroom = false;
      }
    }

    eventBus.on('mainNavigation.hide', this.events.onHide);
    eventBus.on('mainNavigation.unHide', this.events.onUnHide);
    eventBus.on('mainNavigation.hideBecauseOfShowroom', this.events.onHideBecauseOfShowroom);
    eventBus.on('mainNavigation.unHideBecauseOfShowroom', this.events.onUnHideBecauseOfShowroom);
  },
  unmounted()
  {
    eventBus.off('mainNavigation.hide', this.events.onHide);
    eventBus.off('mainNavigation.unHide', this.events.onUnHide);
    eventBus.off('mainNavigation.hideBecauseOfShowroom', this.events.onHideBecauseOfShowroom);
    eventBus.off('mainNavigation.unHideBecauseOfShowroom', this.events.onUnHideBecauseOfShowroom);
  }
}
</script>