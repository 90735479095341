<template>
    <div
    class="model-viewer"
    :class="{'model-viewer--is-loading': isLoading}"
    ref="iframeWrap">
    </div>
</template>

<script>
import eventBus from '@/helpers/eventBus';

export default {
  name: 'ModelViewer',
  props: {
      src: String,
      distance: Number
  },
  data() {
      return {
          isLoading: true,
          omv: null,
          latestPointerDownState: false,
      }
  },
  mounted()
  {
    this.initializeIframe();

    this.events = {
        onResetModel: ()=>{
            this.initializeIframe();
        },
    }

    eventBus.on('modelViewer.resetModel', this.events.onResetModel);
  },
  beforeUnmount()
  {
    if (this.omv && this.omv.window)
    {
        this.omv.window.removeEventListener('pointerdown', this.handlePointerDown);
        this.omv.window.removeEventListener('pointerup', this.handlePointerUp);
        this.omv.window.removeEventListener('pointermove', this.handlePointerMove);
        this.omv.window.removeEventListener('wheel', this.handleWheel);
    }

    eventBus.off('modelViewer.resetModel', this.events.onResetModel);
  },
  methods:
  {
    handlePointerDown()
    {
        this.latestPointerDownState = true;
    },
    handlePointerUp()
    {
        this.latestPointerDownState = false;
    },
    handlePointerMove()
    { 
        if (this.latestPointerDownState)
        {
            this.$emit('on-drag');
        }
    },
    handleWheel()
    {
        this.$emit('on-wheel');
    },
    initializeIframe()
    {
        this.isLoading = true;

        var iframe = document.createElement('iframe');

        iframe.onload = ()=>{

            window.omv = iframe.contentWindow;

            var modelSource = this.src;

            // NOTE: Hack to make sure model is found in offline viewer verson
            modelSource = modelSource.replace("./media/", "../media/");

            var config = [
                {
                    modelUrl: modelSource,
                    hotspots: []
                }
            ];

            window.omv.initialize(window, config, {
                dynamicHotspotListing: false,
                debugMode: false, // NOTE: Use this to see fps + yaw,pitch
                cameraClearcolor: "FFFFFFFF",
                cameraMinZoomMultiplier: 0.6,
                cameraMaxZoomMultiplier: 2.5,
                hotspotScale: 1,
                orbitMouseSensitivity: 0.2,
                orbitIdleTimer: 0,
                externalContent: [
                    { "type": "script", "url": "./omv-engine/js/pe-bloom.js", "name": "pe-bloom.js", "applyTo": "camera", "scriptName": "bloom", "scriptAttributes": { "bloomIntensity": 0.3, "bloomThreshold": 0.25, "blurAmount": 3 } }, 
                    { "type": "script", "url": "./omv-engine/js/pe-brightnessContrast.js", "name": "pe-brightnessContrast.js", "applyTo": "camera", "scriptName": "brightnessContrast", "scriptAttributes": { "brightness": 0/*-0.078*/, "contrast": 0.172 } }, 
                    { "type": "script", "url": "./omv-engine/js/pe-huesaturation.js", "name": "pe-huesaturation.js", "applyTo": "camera", "scriptName": "hueSaturation", "scriptAttributes": { "hue": 0, "saturation": -0.101 } }
                ]
            })
            .then(model => {

                this.omv = window.omv;

                this.omv.window.addEventListener('pointerdown', this.handlePointerDown);
                this.omv.window.addEventListener('pointerup', this.handlePointerUp);
                this.omv.window.addEventListener('pointermove', this.handlePointerMove);
                this.omv.window.addEventListener('wheel', this.handleWheel);

                // NOTE: Set initial model position and wait a bit before setting isLoading to false (which makes the model visible)
                
                // NOTE: This dosen't seem to be intuitive enough for CMS use...
                var pivot = null; //{x:0, y:0, z: 0};

                var distance = this.distance; // NOTE: 0 defaults to automatically getting a good distance it seems...

                this.omv.setActiveCameraPosition(0.001 /*note: yaw*/, 0.001 /*note pitch*/, pivot, distance, null, 0.001 /*note movementtime*/);

                setTimeout(()=>{
                    this.isLoading = false;
                    this.$emit('loaded', model, window.omv);
                }, 250);


            })
            .catch(e => {
                console.error(e);
            });
        }

        iframe.src = './playcanvas-and-omv/omv-display.html';

        this.$refs.iframeWrap.innerHTML = '';
        this.$refs.iframeWrap.appendChild(iframe);

        }
    }
}
</script>