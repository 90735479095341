<template>
    <canvas ref="canvas">
    </canvas>
</template>

<script>
import { getPreloadedImage } from '@/helpers/assetHelper';

export default {
  name: 'PreloadedImage',
  components: {},
  props: {
      src: String
  },
  data()
  {
    return {}
  },
  mounted()
  {
    var imageElement = getPreloadedImage(this.src);

    if (imageElement)
    {
        this.$refs.canvas.width = imageElement.width;
        this.$refs.canvas.height = imageElement.height;
        this.ctx = this.$refs.canvas.getContext('2d');
    
        this.ctx.drawImage(imageElement, 0, 0);
    }

  }
}
</script>
