import store from '@/store';
import router from '@/router';

// NOTE: Handy functions for doing navigation related stuff

// NOTE: Functions for getting content

function getCurrentSceneContent()
{
    var currentPath = router.currentRoute.value.path;
    return getSceneContentByPath(currentPath);
}

// function getCurrentParentSceneContent() {}

function getSceneContentByPath(path)
{
    var result = {
        sceneData: null,
        contentComponentData: null,
    };

    // NOTE: Look for matching path in content components
    store.state.contentComponents.forEach((contentComponent)=>{
        if (path === contentComponent.path)
        {
            result.contentComponentData = contentComponent;

            // NOTE: If we locate a matching content component. Update scene path to make sure we get the scene the content compnent is attached to
            path = contentComponent.scene.path;
        }
    });

    // NOTE: Look for matching path in scenes
    store.state.scenes.forEach((scene)=>{
        if (
            (path === scene.path) || // NOTE: Match if path is identical
            (scene.type === 'productsScene' && path.indexOf(scene.path) == 0) // NOTE: Match if current path start with scene path
        )
        {
            result.sceneData = scene;
        }
    });

    return result;
}

function getSceneContentByID(id)
{
    var result = {
        sceneData: null,
        contentComponentData: null,
    };

    // NOTE: Used for getting scene data if ID matches a content component rather than a scene
    var path = null;

    // NOTE: Look for matching path in content components
    store.state.contentComponents.forEach((contentComponent)=>{
        if (id === contentComponent.id)
        {
            result.contentComponentData = contentComponent;

            // NOTE: If we locate a matching content component. Update scene path to make sure we get the scene the content compnent is attached to
            path = contentComponent.scene.path;
        }
    });

    // NOTE: Look for matching path in scenes
    store.state.scenes.forEach((scene)=>{
        if (id === scene.id)
        {
            result.sceneData = scene;
        }
        else if (path === scene.path)
        {
            result.sceneData = scene;
        }
    });

    return result;
}

function getProductBySlug(slug)
{
    var result = null;

    store.state.products.forEach((product)=>{
        if (product.slug === slug)
        {
            result = product;
        }
    });

    return result;
}

function getPathByID(id)
{
    var result = null;

    var sceneContent = getSceneContentByID(id);
    if (sceneContent.contentComponentData)
    {
        result = sceneContent.contentComponentData.path;
    }
    else if (sceneContent.sceneData)
    {
        result = sceneContent.sceneData.path;
    }
    else
    {
        console.error('Invalid path');
    }

    return result;
}


// NOTE: Functions for going places

function goToPath(path)
{
    router.push({path: path, query: router.currentRoute.value.query});
}

function goToPathByID(id)
{
    var path = getPathByID(id);
    if (path)
    {
        goToPath(path);
    }
}

function goToParentScene()
{
    var pathToGoTo = null;
    var sceneContent = getCurrentSceneContent();
    if (sceneContent.sceneData && sceneContent.sceneData.parentId)
    {
        pathToGoTo = sceneContent.sceneData.parentId;
    }

    // NOTE: Handle special cases
    /*
    if (sceneContent.sceneData && sceneContent.sceneData.type === 'productsScene')
    {
        if (router.currentRoute.value.params.productSlug)
        {
            pathToGoTo = sceneContent.sceneData.id;
        }
    }
    */

    if (pathToGoTo)
    {
        goToPathByID(pathToGoTo);
    }
}

function closeComponentIfOpen()
{
    var sceneContent = getCurrentSceneContent();
    goToPath(sceneContent.sceneData.path);
}

// NOTE: Misc

function getBreadcrumbs()
{
    var breadCrumbs = [];

    var sceneContent = getCurrentSceneContent();

    // NOTE: Build breadcrumbs for special cases (parameterized scenes)
    if (sceneContent.sceneData && sceneContent.sceneData.type === 'productsScene')
    {
        if (router.currentRoute.value.params.productSlug)
        {
            var product = getProductBySlug(router.currentRoute.value.params.productSlug);
            if (product)
            {
                breadCrumbs.push({
                    id: 'product',
                    title: product.name,
                    path: null
                });
            }
        }
    }

    // NOTE: Build breadcrumbs from scene hierachy
    while (sceneContent && sceneContent.sceneData)
    {
        breadCrumbs.push({
            preventClick: (sceneContent.sceneData.type === 'productsScene'),
            id: sceneContent.sceneData.id,
            title: sceneContent.sceneData.title,
            path: sceneContent.sceneData.path
        });

        if (sceneContent.sceneData.parentId)
        {
            sceneContent = getSceneContentByID(sceneContent.sceneData.parentId);
        }
        else {
            sceneContent = null;
        }
    }

    breadCrumbs.reverse();

    return breadCrumbs;
}

export {
    getCurrentSceneContent,
    getSceneContentByPath,
    getSceneContentByID,
    getProductBySlug,
    getPathByID,
    goToPath,
    goToPathByID,
    goToParentScene,
    closeComponentIfOpen,
    getBreadcrumbs
};
