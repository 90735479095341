import { createRouter, createWebHashHistory } from 'vue-router'
import SceneView from '../views/SceneView.vue'

const routes = [
  // TODO: Add configurator route here
  {
    name: "scenes",
    path: "/:pathMatch(.*)*",
    component: SceneView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;