<template>

    <div class="background-video">

      <div class="background-video__preview" :style="positionCss" @click="videoIsOpen = true">

        <div class="background-video__frame" :style="videoFrameCss">
          <video v-if="videoPreviewFile" :src="mediaUrl(videoPreviewFile)"
            muted 
            autoplay 
            loop 
            playsinline>
          </video>
        </div>

        <div class="background-video__toggle-btn"></div>

      </div>

      <Popup type="video" v-if="videoIsOpen" @close="videoIsOpen = false">
        <BasicVideo
          :videoMediaFile="videoFile"
          :videoLink="videoLink"
          :controls="true"
          :loop="false"
          :autoplay="true"
          :muted="false"/>
      </Popup>

    </div>

</template>

<script>
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import Popup from '@/components/Popup';
import BasicVideo from '@/components/BasicVideo';

export default {
  name: 'HotspotButton',
  components: {Popup, BasicVideo},
  props:
  {
      videoPreviewFile: Object,
      videoFile: Object,
      videoLink: String,
      positionCss: String,
      videoFrameCss: String
  },
  data()
  {
    return {
      videoIsOpen: false
    }
  },
  methods:
  {
    mediaUrl(image)
    {
      return getUmbracoMediaItemSrc(image);
    }
  }
}
</script>