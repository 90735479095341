<template>
  <Popup type="content" @close="$emit('close')">
      <div class="content-popup">

        <div class="content-popup__tabs">

          <div class="content-popup__tab"
            v-for="(tab,i) in tabs" :key="i"
            :class="{'content-popup__tab--selected': i === currentTabIndex}">
            <div class="content-poup__slideshow">
              <Slider v-if="tab.slides" :slides="tab.slides" style-variant="default" @openVideo="openVideo"/>
            </div>
            <div class="content-popup__text-content">
              <div class="content-popup__heading">{{tab.heading}}</div>
              <div class="content-popup__sub-heading" v-html="tab.subHeading"></div>
              <div class="content-popup__rich-text" v-html="tab.text"></div>
              <div class="content-popup__call-to-action">

                <template v-for="(link, j) in tab.callToActionLinks" :key="j">

                  <Button v-if="link.sceneContentReference" class="content-popup__button" :router-link="getPathById(link.sceneContentReference)">{{link.text}}</Button>

                  <Button v-else-if="link.externalURL" class="content-popup__button" :href="link.externalURL">{{link.text}}</Button>

                </template>

              </div>

            </div>
          </div>

        </div>

        <div class="content-popup__tab-navigation" v-if="tabs && tabs.length > 1">
          <div v-for="(tab,i) in tabs" :key="i"
            class="content-popup__tab-navigation-dot"
            :class="{'content-popup__tab-navigation-dot--selected': i === currentTabIndex}"
            @click="goToTab(i)">
          </div>
        </div>
        
      </div>
  </Popup>

  <Popup type="video" v-if="popupVideoData" @close="popupVideoData = null">
    <BasicVideo
      :videoMediaFile="popupVideoData.videoMediaFile"
      :videoLink="popupVideoData.videoLink"
      :controls="true"
      :loop="false"
      :autoplay="true"
      :muted="false"/>
  </Popup>

</template>

<script>
import {getPathByID} from '@/helpers/navigationHelper';
import Popup from '@/components/Popup';
import Button from '@/components/Button';
import Slider from '@/components/Slider';
import BasicVideo from '@/components/BasicVideo';

export default {
  name: 'ContentPopup',
  components: {Popup, Button, Slider, BasicVideo},
  emits: ["close"],
  props: {
    tabs: Array
  },
  data()
  {
    return {
      currentTabIndex: 0,
      popupVideoData: null
    }
  },
  created()
  {
  },
  methods:
  {
    openVideo(videoData)
    {
      this.popupVideoData = videoData;
    },
    goToTab(tabIndex)
    {
      this.currentTabIndex = tabIndex;
    },
    getPathById(targetId)
    {
      return getPathByID(targetId);
    }
  }
}
</script>
