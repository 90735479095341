<template>

    <router-link v-if="routerLink" class="button" :to="{path: routerLink, query: $route.query}">
        <div class="button__title">
            <slot></slot>
        </div>
        <div class="button__icon"></div>
    </router-link>

    <a v-else-if="href" class="button" :href="href" target="_blank">
        <div class="button__title">
            <slot></slot>
        </div>
        <div class="button__icon"></div>
    </a>

    <div v-else class="button">
        <div class="button__title">
            <slot></slot>
        </div>
        <div class="button__icon"></div>
    </div>

</template>

<script>

export default {
  name: 'Button',
  props: {
    href: String,
    routerLink: String
  }
}
</script>