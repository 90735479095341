<template>

  <MainNavigation/>

  <transition name="fade" mode="out-in" @after-leave="afterTransition">
    <Scene
      :style="'transform-origin: '+transitionZoomTransformOrigin"
      :class="[{'scene--transition-effect-fade': (transitionType === 'fade')}, {'scene--transition-effect-zoom': (transitionType === 'zoomAndFade')}]"
      v-if="sceneContent.sceneData"
      :key="sceneContent.sceneData.id"
      :background-image="sceneContent.sceneData.data.backgroundImage"
      :background-video="sceneContent.sceneData.data.backgroundVideo">

      <!-- NOTE: Child views are rendered here if any -->
      <router-view></router-view>

      <SceneComponentRenderer v-if="sceneContent.sceneData.sceneComponents" :sceneComponents="sceneContent.sceneData.sceneComponents"/>
    </Scene>
  </transition>

  <ContentComponentRenderer v-if="sceneContent.contentComponentData" :contentComponentData="sceneContent.contentComponentData"/>

</template>

<script>
// @ is an alias to /src
import Scene from '@/components/Scene';
import SceneComponentRenderer from '@/components/SceneComponentRenderer';
import ContentComponentRenderer from '@/components/ContentComponentRenderer';
import {getSceneContentByPath} from '@/helpers/navigationHelper';
import ProductsView from '@/views/ProductsView';
import MeetingView from '@/views/MeetingViewConfiguration';
import SingleProductView from '@/views/SingleProductView';
import MainNavigation from '@/components/MainNavigation';

export default {
  name: 'SceneView',
  components: {
    Scene: Scene,
    SceneComponentRenderer: SceneComponentRenderer,
    ContentComponentRenderer: ContentComponentRenderer,
    MainNavigation: MainNavigation
  },
  data()
  {
    return {
      sceneContent: {},
      transitionType: null,
      transitionZoomTransformOrigin: '50% 50%',
    }
  },
  beforeRouteUpdate(to, from, next)
  {
    // NOTE: Get scene data on update
    this.sceneContent = getSceneContentByPath(to.path);

    if (this.dynamicallyCreateChildRoutesIfNeeded(this.sceneContent.sceneData))
    {
      // NOTE: If new route was created, ensure that it is loaded
      next({path: to.path, query: this.$route.query});
      return;
    }

    // NOTE: Cancel transition if next route is a content component, by setting the default.
    if (this.sceneContent.contentComponentData)
    {
      this.$store.commit('setSceneTransitionEffect', {type: 'fade'});
    }

    this.updateTitleAndMetaDesc(this.sceneContent, to);

    next();
  },
  created()
  {
    // NOTE: Get initial scene data
    this.unsubscribe = this.$store.subscribe((mutation, state) =>
    {
      // NOTE: React to the manifest being ready for use
      if (mutation.type === 'attatchSortedManifestData')
      {
        this.sceneContent = getSceneContentByPath(this.$route.path);

        if (this.dynamicallyCreateChildRoutesIfNeeded(this.sceneContent.sceneData))
        {
          // NOTE: If new route was created, ensure that it is loaded
          this.$router.replace({path: this.$route.fullPath, query: this.$route.query});
        }
      }

      // NOTE: React to transitions
      else if (mutation.type === 'setSceneTransitionEffect')
      {
        this.transitionType = state.sceneTransitionEffect.type;
        if (state.sceneTransitionEffect.type === 'zoomAndFade')
        {
          this.transitionZoomTransformOrigin = state.sceneTransitionEffect.transformOrigin;
        }
      }

      this.updateTitleAndMetaDesc(this.sceneContent, this.$route);
    })
  },
  methods: {
    getProductBySlug(slug)
    {
      var result = null;

      let products = this.$store.state.products;

      products.forEach((product)=>
      {
        if (product.slug === slug)
        {
          result = product;
        }
      });
      
      return result;
    },
    updateTitleAndMetaDesc(sceneContent, route)
    {
      let title = 'Intellian World';
      let metaDescription = '';

      if (sceneContent.contentComponentData)
      {
        title = (sceneContent.contentComponentData.data.title) ? sceneContent.contentComponentData.data.title : '';
        metaDescription = (sceneContent.contentComponentData.data.metaDescription) ? sceneContent.contentComponentData.data.metaDescription : '';
      }
      else if (sceneContent.sceneData)
      {
        title = (sceneContent.sceneData.data.title) ? sceneContent.sceneData.data.title : '';
        metaDescription = (sceneContent.sceneData.data.metaDescription) ? sceneContent.sceneData.data.metaDescription : '';

        // NOTE: Check for product
        if (sceneContent.sceneData.type === "productsScene")
        {
          let productSlug = route.params.productSlug;
          let product = this.getProductBySlug(productSlug);

          if (product)
          {
            title = title + " - " +product.data.title;
            metaDescription = (product.data.metaDescription) ? product.data.metaDescription : '';
          }
        }
      }

      // NOTE: Update DOM
      document.title = title;
      let metaTag = document.querySelector('#metaDescription');
      if (metaTag)
      {
        metaTag.setAttribute("content", metaDescription);
      }
    },
    afterTransition()
    {
      // NOTE: Reset transition effect
      this.$store.commit('setSceneTransitionEffect', {type: 'fade'});
    },
    dynamicallyCreateChildRoutesIfNeeded(sceneData)
    {
      // NOTE: Create child routes for products scene type
      if (sceneData.type === "productsScene")
      {
        let routeName = 'products-' + sceneData.id;
        let childRouteName = 'product-' + sceneData.id;

        let routeAlreadyExists = this.$router.hasRoute(routeName);
        if (!routeAlreadyExists)
        {
          // NOTE: Add products scene as child to scenes
          this.$router.addRoute('scenes', {
            name: routeName,
            path: sceneData.path + ':pathMatch(.*)*',
            component: ProductsView,
            props: {scenePath: sceneData.path, categoryId: sceneData.data.category}
          });
          
          // NOTE: Add single product scene as child to products scene
          this.$router.addRoute(routeName, {
            name: childRouteName,
            path: ':productSlug/',
            component: SingleProductView,
            props: {scenePath: sceneData.path, categoryId: sceneData.data.category}
          });

          return true;
        }
      }
      else if (sceneData.type === "meetingScene")
      {
        let routeName = 'meeting';

        let routeAlreadyExists = this.$router.hasRoute(routeName);
        if (!routeAlreadyExists)
        {
          // NOTE: Add products scene as child to scenes
          this.$router.addRoute('scenes', {
            name: routeName,
            path: sceneData.path + ':pathMatch(.*)*',
            component: MeetingView,
            props: {scenePath: sceneData.path}
          });
          
          return true;
        }
      }

      return false;
    },
  },
  unmounted()
  {
    this.unsubscribe();
  }
}
</script>