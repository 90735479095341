<template>

    <!-- NOTE: We manually map data to vue components here -->

    <template v-for="(sceneComponent, i) in sceneComponents" :key="i">

      <HotspotButton v-if="sceneComponent.nsAlias === 'hotspotButton'" :key="i"
        :x="sceneComponent.positionX"
        :y="sceneComponent.positionY"
        :title="sceneComponent.title"
        :target-id="sceneComponent.sceneContentReference"
        :external-url="sceneComponent.externalURL"
        :has-transition="true"
        :has-pulse="true"
        :transition-delay-ms="hotspotDelaysByIndex[i]"
        :pulse-interval-ms="hotspotDelaysByIndex[i] + pulseIntervalMs"
        :icon="sceneComponent.icon"
        :hideIfOffline="sceneComponent.hideIfOffline"
        :hideIfNotInMeeting="sceneComponent.hideIfNotInMeeting"
        :hideIfInMeeting="sceneComponent.hideIfInMeeting"
        :direction="(sceneComponent.flipDirection) ? 'right' : 'left'"
      />

      <BackgroundVideo v-if="sceneComponent.nsAlias === 'backgroundVideo'" :key="i"
        :positionCss="sceneComponent.positionCss"
        :videoFrameCss="sceneComponent.videoFrameCss"
        :videoPreviewFile="sceneComponent.videoPreviewFile"
        :videoFile="sceneComponent.videoFile"
        :videoLink="sceneComponent.videoLink"
      />

      <Logo v-if="sceneComponent.nsAlias === 'logo'" key="i"
        :css="sceneComponent.css"
        :style="sceneComponent.style"
      />

    </template>

</template>

<script>
import HotspotButton from '@/components/scene-components/HotspotButton';
import BackgroundVideo from '@/components/scene-components/BackgroundVideo';
import Logo from '@/components/scene-components/Logo';

export default {
  name: 'SceneComponentRenderer',
  components: {
    HotspotButton,
    BackgroundVideo,
    Logo
  },
  props: {
      sceneComponents: Array
  },
  data()
  {
    return {
      initialDelayMs: 400,
      delayOffsetMs: 200,
      pulseIntervalMs: 15000,
      hotspotDelaysByIndex: []
    }
  },
  created()
  {
    // NOTE: Populate hotspotIndexes array with data to use to calculate delay time...
    var i = 0;
    this.sceneComponents.forEach((component, j)=>{
      if (component.nsAlias === 'hotspotButton')
      {
        this.hotspotDelaysByIndex[j] = this.initialDelayMs + (i * this.delayOffsetMs);
        i++;
      }
    });
  }
}
</script>
