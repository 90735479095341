var config = {
   manifestPath: 'assets/json/en/manifest.json',
   tempMediaPath: 'https://intellianeventumbraco.azurewebsites.net/output/event/',
   umbracoMediaPath: '',
}
 
if (window.location.hostname === "localhost" )
{
   config.tempMediaPath = '';
   config.manifestPath = 'https://intellianeventumbraco.azurewebsites.net/output/event/assets/json/en/manifest.json';
   config.umbracoMediaPath = 'https://intellianeventumbraco.azurewebsites.net';
}
 
// TODO: Add list of smaller assets to preload here. Like SVG icons and the logo. For preloading

export default config;