<template>
  <div class="slider" :class="'slider--'+styleVariant">
    <div class="slider__wrap">
      <div class="slider__slides" :style="'transform: translateX(-'+ currentSlideIndex*100 +'%)'">
        <div class ="slider__slide" :class="{'slider__slide--current': (currentSlideIndex === i)}" v-for="(slide,i) in slides" :key="i">

          <div class="slider__slide-inner">

            <template v-if="slide.image">
                <img :src="mediaUrl(slide.image)"/>
            </template>

          <template v-if="(slide.previewImage || slide.previewVideoFile || slide.videoFile || slide.videoLink)">
            
            <div class="slider__video-preview" :class="{'slider__video-preview--fullscreen-icon-instead-of-arrow': slide.videoFile}">

              <img v-if="slide.previewImage" :src="mediaUrl(slide.previewImage)" @click="$emit('open-video', {videoMediaFile: slide.videoFile, videoLink: slide.videoLink})"/>

              <BasicVideo v-if="(slide.videoFile)"
                :videoMediaFile="slide.videoFile"
                :loop="true"
                :autoplay="false"
                :muted="true"
                :active="(currentSlideIndex === i)"
                @click="$emit('open-video', {videoMediaFile: slide.videoFile, videoLink: slide.videoLink})"/>

            </div>

          </template>

          </div>

        </div>
      </div>
    </div>
    <div class="slider__navigation" v-if="slides.length > 1">
      <div
        class="slider__arrow slider__arrow--left"
        :class="{'slider__arrow--disabled': currentSlideIndex <= 0}"
        @click="prevSlide"></div>
      <div 
        class="slider__arrow slider__arrow--right"
        :class="{'slider__arrow--disabled': currentSlideIndex+1 >= slides.length}"
        @click="nextSlide"></div>
    </div>
  </div>
</template>

<script>
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import BasicVideo from '@/components/BasicVideo';

export default {
  name: 'Slider',
  components: {BasicVideo},
  props: {
    slides: Object,
    styleVariant: String
  },
  data()
	{
		return {
			currentSlideIndex: 0
		}
	},
  methods:
  {
    goToSlide(slideIndex)
    {
      this.currentSlideIndex = slideIndex;
    },
    prevSlide()
		{
			if (this.currentSlideIndex > 0)
			{
				this.currentSlideIndex--;
			}
		},
		nextSlide()
		{
			if (this.currentSlideIndex+1 < this.slides.length)
			{
				this.currentSlideIndex++;
			}
		},
    mediaUrl(image)
    {
      return getUmbracoMediaItemSrc(image);
    }
  }
}
</script>